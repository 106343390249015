import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../@types/api'
import PromotionListItem from '../components/Promotion/PromotionListItem'
import api from '../helpers/api'
import { ApplicationState } from '../store'
import { requestUser } from '../store/user/user.actions'

const Promotion = () => {
	const dispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefix = prefixState.info,
		user = userState.info

	const [acceptingBonus, setAcceptingBonus] = useState('')
	const acceptBonus = async (key: string) => {
		setAcceptingBonus(key)

		try {
			const res = await api.post<void, APIResponse>(
				`/member/promotions/${key}`
			)

			dispatch(requestUser())
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message)
		} finally {
			setAcceptingBonus('')
		}
	}

	const changeAutoBonus = async (key: string) => {
		try {
			const res = await api.patch<void, APIResponse>(
				`/member/promotions/${key}`
			)

			dispatch(requestUser())
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message)
		}
	}

	if (!prefix || !user) {
		return <div>ไม่สามารถใช้งานโปรโมชั่นได้ในขณะนี้</div>
	}
	return (
		<div className="wrapper">
			{prefix.promotions.map((promotion) => (
				<PromotionListItem
					promotion={promotion}
					defaultAutoBonus={user.autoBonus}
					acceptingBonus={acceptingBonus}
					acceptBonus={acceptBonus}
					changeAutoBonus={changeAutoBonus}
					key={promotion.key}
				/>
			))}

			<div className="non-bonus-auto">
				<label className="radio-group">
					<input
						type="radio"
						name="autoBonus"
						value="off"
						defaultChecked={user.autoBonus === 'off'}
						onClick={() => changeAutoBonus('off')}
					/>
					<span>ไม่รับรับโบนัสอัตโนมัติ</span>
					<span className="checkmark" />
				</label>
			</div>
		</div>
	)
}

export default Promotion
