import React, { ChangeEvent, FC } from 'react'
import { bankNames } from '../../helpers/bank'
import BaseButton from '../Base/BaseButton'

interface RegisterStep2Props {
	bankCode: string
	isSubmitting: boolean

	handleChange: (e: ChangeEvent<any>) => void
}
const RegisterStep2: FC<RegisterStep2Props> = ({
	bankCode,
	isSubmitting,
	handleChange,
}) => (
	<>
		<fieldset className="form-group">
			<label>ธนาคาร</label>
			<select
				name="bankCode"
				className="form-control form-control-lg custom-select"
				required
				onChange={handleChange}>
				<option>เลือกธนาคารของท่าน ...</option>
				{Object.keys(bankNames).map((bankCode) => (
					<option value={bankCode} key={bankCode}>
						{bankNames[bankCode]}
					</option>
				))}
			</select>
		</fieldset>
		<fieldset className="form-group">
			<label>เลขที่บัญชี</label>
			<input
				type="text"
				name="bankNumber"
				className="form-control form-control-lg"
				placeholder="เลขที่บัญชี"
				required
				onChange={handleChange}
			/>
		</fieldset>
		<fieldset className="form-group">
			<label>ชื่อจริง</label>
			<input
				type="text"
				name="firstName"
				className="form-control form-control-lg"
				placeholder="ชื่อจริง"
				required
				onChange={handleChange}
			/>
		</fieldset>
		<fieldset className="form-group">
			<label>นามสกุล</label>
			<input
				type="text"
				name="lastName"
				className="form-control form-control-lg"
				placeholder="นามสกุล"
				required
				onChange={handleChange}
			/>
		</fieldset>
		{bankCode === 'SCB' && (
			<>
				<fieldset className="form-group">
					<label>ชื่อจริง (ภาษาอังกฤษ)</label>
					<input
						type="text"
						name="firstNameEN"
						className="form-control form-control-lg"
						placeholder="ชื่อจริง (ภาษาอังกฤษ)"
						required
						onChange={handleChange}
					/>
				</fieldset>
				<fieldset className="form-group">
					<label>นามสกุล (ภาษาอังกฤษ)</label>
					<input
						type="text"
						name="lastNameEN"
						className="form-control form-control-lg"
						placeholder="นามสกุล (ภาษาอังกฤษ)"
						required
						onChange={handleChange}
					/>
				</fieldset>
			</>
		)}
		<fieldset className="form-group">
			<BaseButton type="submit" loading={isSubmitting}>
				ถัดไป
			</BaseButton>
		</fieldset>
	</>
)

export default RegisterStep2
