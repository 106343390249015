import qs from 'querystring'
import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Route, Router, Switch} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import './assets/app.scss'
import './assets/base.css'
import PrefixContainer from './components/Container/PrefixContainer'
import AuthLayout from './components/Layout/AuthLayout'
import history from './helpers/history'
import store from './store'
import Login from './views/Login'
import Register from './views/Register'

declare global {
    interface Window {
        Winwheel: any
    }
}

const params = qs.parse(window.location.search.slice(1))
const prefix = localStorage.getItem('x-prefix')?.toUpperCase()
if (!params.prefix && prefix) {
    localStorage.setItem('x-prefix', prefix)
    history.push('?prefix=' + prefix)
} else {
    const paramPrefix = params.prefix?.toString().toUpperCase()
    localStorage.setItem('x-prefix', paramPrefix ?? 'no-prefix')
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PrefixContainer>
                <Router history={history}>
                    <Switch>
                        <Route path="/login" component={AuthLayout(Login)}/>
                        <Route
                            path="/register"
                            component={AuthLayout(Register)}
                        />

                        <App/>
                    </Switch>
                </Router>
            </PrefixContainer>
            <ToastContainer/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
