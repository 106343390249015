import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import TruewalletQRDialog from '../components/Deposit/TruewalletQRDialog'
import RedeemDialog from '../components/Deposit/RedeemDialog'
import { makePath } from '../helpers/path'
import { ApplicationState } from '../store'
import { requestDepositTo } from '../store/deposit/deposit.actions'

const Deposit = () => {
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)
	const depositState = useSelector((state: ApplicationState) => state.deposit)

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(requestDepositTo())
	}, [dispatch])

	const isBankEnable = depositState.depositToList.filter(({ bank }) => bank !== 'truewallet').length > 0
	const isTruewalletEnable = depositState.depositToList.filter(({ bank }) => bank === 'truewallet').length > 0
	const [openTruewalletQRDialog, setOpenTruewalletQRDialog] = useState(false)
	const [openRedeemDialog, setOpenRedeemDialog] = useState(false)

	if (!prefix || !depositState.isLoaded) {
		return (
			<div className="text-center">
				<i className="fas fa-spinner fa-spin" />
			</div>
		)
	}

	return (
		<Fragment>
			<div className="wrapper">
				<div className="topup-method">
					<p>กรุณาเลือกวิธีการเติมเงิน</p>
					{isBankEnable && (
						<Link to={makePath('/deposit/bank')} className="topup-card">
							<div className="topup-card__wrapper">
								<i className="fa fa-university fa-2x" />
								<span>โอนผ่านธนาคาร</span>
							</div>
						</Link>
					)}
					{isTruewalletEnable && (
						<Link to={makePath('/deposit/wallet')} className="topup-card">
							<div className="topup-card__wrapper">
								<i className="fab fa-google-wallet fa-2x" />
								<span>โอนผ่านทรูมันนี่วอลเล็ท</span>
							</div>
						</Link>
					)}
					{depositState.truewalletQREnable && (
						<div className="topup-card" onClick={() => setOpenTruewalletQRDialog(true)}>
							<div className="topup-card__wrapper">
								<i className="fas fa-qrcode fa-2x" />
								<span>
									เติมเงินผ่าน QR Code
									<br />
									<small>(ทรูมันนี่วอลเล็ท)</small>
								</span>
							</div>
						</div>
					)}
					{depositState.ambPayEnable && (
						<Link to={makePath('/deposit/ambpay')} className="topup-card">
							<div className="topup-card__wrapper">
								<i className="fad fa-qrcode fa-2x" />
								<span>
									เติมเงินผ่าน QR Code
									<br />
									<small>(ธนาคาร)</small>
								</span>
							</div>
						</Link>
					)}

					{/* TODO : รับค่า depositState ของ redeemEnable */}
					{depositState.redeemEnable && ( 
						<div className="topup-card" onClick={() => setOpenRedeemDialog(true)}>
							<div className="topup-card__wrapper">
							<i className="fad fa-ticket-alt fa-2x"></i>
								<span>เติมคูปอง</span>
							</div>
						</div>
					)}
				</div>
			</div>

			<TruewalletQRDialog isOpen={openTruewalletQRDialog} onClose={() => setOpenTruewalletQRDialog(false)} />
			<RedeemDialog isOpen={openRedeemDialog} onClose={() => setOpenRedeemDialog(false)} />
		</Fragment>
	)
}

export default Deposit
