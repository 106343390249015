import { useFormik } from 'formik'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import BaseButton from '../components/Base/BaseButton'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { setUser } from '../store/user/user.actions'

const Login = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			phone: '',
			password: '',
		},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const res = await AuthService.login({
					phone: values.phone.replace(/-/g, ''),
					password: values.password,
				})

				dispatch(setUser(res))
				history.push(makePath('/wallet'))
			} catch ({ message }) {
				toast.error(message)
				setSubmitting(false)
			}
		},
	})
	return (
		<>
			<form className="mt-1" onSubmit={handleSubmit}>
				<fieldset className="form-group">
					<label>เบอร์โทรศัพท์</label>
					<NumberFormat
						format="###-###-####"
						mask="_"
						type="tel"
						placeholder="เบอร์โทรศัพท์"
						name="phone"
						className="form-control form-control-lg"
						required
						onChange={handleChange}
					/>
				</fieldset>
				<fieldset className="form-group">
					<label>รหัสผ่าน</label>
					<input
						type="password"
						name="password"
						className="form-control form-control-lg"
						placeholder="รหัสผ่าน"
						required
						onChange={handleChange}
					/>
				</fieldset>
				<fieldset className="form-group">
					<BaseButton type="submit" loading={isSubmitting}>
						เข้าสู่ระบบ
					</BaseButton>
				</fieldset>
			</form>
			<div className="text-center mt-1">
				<span>ต้องการสมัครสมาชิกใช่ไหม? </span>
				<Link to={makePath('/register')}>สมัครสมาชิก</Link>
			</div>
		</>
	)
}

export default Login
