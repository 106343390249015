import React, { FC } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { Prefix } from '../../@types/prefix'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'

interface TopbarProps {
	prefix?: Prefix
	member?: Member

	pathname: string
}
const Topbar: FC<TopbarProps> = ({ prefix, member, pathname }) => {
	const loc = useLocation()
	const history = useHistory()
	const launchGame = () => {
		if (!member?.game.meta) {
			toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
			return
		}

		history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
	}

	return (
		<div className="topbar">
			{pathname === '/wallet' && (
				<div className="topbar__logo">
					<a href="#logo">
						<img src={prefix?.logo} alt="LOGO" />
					</a>
				</div>
			)}
			<div className="topbar__welcome">
				<h1>
					{topbarText[loc.pathname] ??
						`สวัสดี, คุณ${member?.firstName}`}
				</h1>
			</div>
			<div className="topbar__member">
				<Link to={makePath('/profile')}>
					<div className="topbar__member--info">
						<h1>
							{member?.firstName} {member?.lastName}
						</h1>
						<p>โปรไฟล์ของคุณ</p>
					</div>
				</Link>
				<button
					type="button"
					className="btn btn--secondary btn-play"
					onClick={launchGame}
				>
					<span>
						เข้าเล่นเกม <i className="fad fa-angle-right" />
					</span>
				</button>
			</div>
		</div>
	)
}

export default Topbar
