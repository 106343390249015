import React, { ChangeEvent, FC, useState } from 'react'
import { Promotion } from '../../@types/prefix'
import BaseButton from '../Base/BaseButton'

interface RegisterStep3Props {
	autoBonus: string
	defaultKnowFrom: string
	showAffiliateCode: boolean
	defaultAffiliateCode: string
	promotions: Promotion[]
	isSubmitting: boolean

	handleChange: (e: ChangeEvent<any>) => void
}
const RegisterStep3: FC<RegisterStep3Props> = ({
	autoBonus,
	defaultKnowFrom,
	showAffiliateCode,
	defaultAffiliateCode,
	promotions,
	isSubmitting,

	handleChange,
}) => {
	const prefixKey = localStorage.getItem('x-prefix')
	const [captchaT, setCaptchaT] = useState(Date.now())
	const captchaURL = `${process.env.REACT_APP_API_ENDPOINT}/register/captcha?prefix=${prefixKey}&t=${captchaT}`

	return (
		<>
			<fieldset className="form-group">
				<label>รหัสผ่าน</label>
				<input
					type="password"
					name="password"
					className="form-control form-control-lg"
					placeholder="รหัสผ่าน"
					required
					onChange={handleChange}
				/>
			</fieldset>
			<fieldset className="form-group">
				<label>ไอดีไลน์</label>
				<input
					type="text"
					name="line"
					className="form-control form-control-lg"
					placeholder="ไอดีไลน์"
					required
					onChange={handleChange}
				/>
			</fieldset>
			<fieldset className="form-group">
				<label>รับโบนัสอัตโนมัติ</label>
				<select
					name="autoBonus"
					className="form-control form-control-lg custom-select"
					required
					onChange={handleChange}
				>
					<option value="off">ไม่รับโบนัสอัตโนมัติ</option>
					{promotions
						.filter((v) => v.amount > 0)
						.map((promotion) => (
							<option value={promotion.key} key={promotion.key}>
								{promotion.name}
							</option>
						))}
				</select>
			</fieldset>
			<fieldset className="form-group">
				<label>ช่องทางที่รู้จักเรา</label>
				<select
					name="knowFrom"
					className="form-control form-control-lg custom-select"
					defaultValue={defaultKnowFrom}
					required
					onChange={handleChange}
				>
					<option>เลือกช่องทางที่รู้จักเรา ...</option>
					<option value="facebook">Facebook</option>
					<option value="line">Line</option>
					<option value="google">Google</option>
					<option value="instargram">Instargram</option>
					<option value="youtube">Youtube</option>
					<option value="friend">เพื่อนแนะนำ</option>
					<option value="other">โฆษณาอื่น ๆ</option>
				</select>
			</fieldset>
			{showAffiliateCode && (
				<fieldset className="form-group">
					<label>รหัสชวนเพื่อน</label>
					<input
						type="text"
						name="affiliateCode"
						className="form-control form-control-lg"
						placeholder="รหัสชวนเพื่อน"
						defaultValue={defaultAffiliateCode}
						required
						onChange={handleChange}
					/>
				</fieldset>
			)}

			<div className="captcha-row">
				<img src={captchaURL} alt="Captcha" />
				<span className="btn-copy" onClick={() => setCaptchaT(Date.now())}>
					<i className="fad fa-redo" />
					ลองรูปอื่น
				</span>
			</div>
			<fieldset className="form-group">
				<label>กรอกรหัสตามภาพ</label>
				<input
					type="text"
					name="captcha"
					className="form-control form-control-lg"
					placeholder="กรอกรหัสตามภาพ"
					required
					onChange={handleChange}
				/>
			</fieldset>
			<fieldset className="form-group">
				<BaseButton type="submit" loading={isSubmitting}>
					สมัครสมาชิก
				</BaseButton>
			</fieldset>
		</>
	)
}

export default RegisterStep3
