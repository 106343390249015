import React, { FC, MouseEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { makePath } from '../../helpers/path'

interface BottomBarProps {
	member: Member | undefined
}
const BottomBar: FC<BottomBarProps> = ({ member }) => {
	const history = useHistory()
	const launchGame = (e: MouseEvent<any>) => {
		e.preventDefault()

		if (!member?.game.meta) {
			toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
			return
		}
		history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
	}

	return (
		<div className="bottombar-mobile">
			<ul className="nav-menu">
				<li className="nav-item">
					<Link to={makePath('/wallet')} className="nav-link">
						<i className="fad fa-wallet" />
						<span>กระเป๋า</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link to={makePath('/deposit')} className="nav-link">
						<i className="fad fa-arrow-down" />
						<span>เติมเงิน</span>
					</Link>
				</li>
				<li className="nav-item middle-item">
					<a href="#game" className="nav-link" onClick={launchGame}>
						<i className="fad fa-dice" />
					</a>
					<span>เข้าเล่นเกม</span>
				</li>
				<li className="nav-item">
					<Link to={makePath('/withdraw')} className="nav-link">
						<i className="fad fa-arrow-up" />
						<span>ถอนเงิน</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link to={makePath('/profile')} className="nav-link">
						<i className="fad fa-user" />
						<span>โปรไฟล์ฉัน</span>
					</Link>
				</li>
			</ul>
		</div>
	)
}

export default BottomBar
