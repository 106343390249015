import classNames from 'classnames'
import React, { FC, Fragment, MouseEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { AskmebetGameProviderConfig, AskmebetLoginResponse, AskmebetSlotGame } from '../../../entities/askmebet'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'

interface AskmebetSlotProps {
	config: Record<string, AskmebetGameProviderConfig>
	games: Record<string, AskmebetSlotGame[]>
}
const AskmebetSlot: FC<AskmebetSlotProps> = ({ config, games }) => {
	const dynamicEnabledProviders = Object.values(config).filter((provider) => provider.enable)
	const [tab, setTab] = useState(dynamicEnabledProviders[0].id)

	const [redirecting, setRedirecting] = useState(false)
	const triggerSlotLogin = (gameID: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()

		setRedirecting(true)
		try {
			const res = await api.post<void, AskmebetLoginResponse>('/askmebet/login/slot', {
				gameID: gameID,
				isMobile: isMobile(),
				productCode: tab,
			})

			window.location.href = res.url
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setRedirecting(false)
		}
	}

	if (redirecting) {
		return null
	}
	return (
		<Fragment>
			<div className="super-tab">
				<div className="tab mb-5 custom-tab-slot">
					{dynamicEnabledProviders
						.filter((provider) => games.hasOwnProperty(provider.id))
						.map((provider) => (
							<div
								className={classNames('tab__link', {
									'tab-active': tab === provider.id,
								})}
								onClick={() => setTab(provider.id)}
								key={provider.id}
							>
								{provider.name}
							</div>
						))}
				</div>
			</div>

			<div className="tab__content slot-wrapper">
				{games.hasOwnProperty(tab) &&
					games[tab]
						.filter((game) => game.name !== 'Bali Vacation')
						.map((game) => (
							<a href="#ambbet-slot" className="slot-card" onClick={triggerSlotLogin(game.id)} key={game.id}>
								<img src={game.imageURL} alt={game.name} className="slot-card__img" />
								<span>{game.name}</span>
							</a>
						))}
			</div>
		</Fragment>
	)
}

export default AskmebetSlot
