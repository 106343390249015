import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'

interface TopbarMobileProps {
	pathname: string
}
const TopbarMobile: FC<TopbarMobileProps> = ({ pathname }) => (
	<div className="topbar-mobile">
		<Link to={makePath('/wallet')} className="topbar-mobile__back">
			<i className="fad fa-chevron-double-left" />
		</Link>
		<div className="topbar-mobile__title">
			<span>{topbarText[pathname] ?? '-'}</span>
		</div>
	</div>
)

export default TopbarMobile
