import moment from 'moment'
import React, { FC } from 'react'
import { Transaction } from '../../@types/transaction'
import { format } from '../../helpers/number'
import { getTxTypeText } from '../../helpers/transaction'

interface PromotionListItemProps {
	tx: Transaction
}
const PromotionListItem: FC<PromotionListItemProps> = ({ tx }) => (
	<div className="history">
		<div className="history__type">
			<h1>รับโบนัส</h1>
			<p>{tx.remark}</p>
		</div>
		<div className="history__amount">
			<div className="history__amount--status">
				<span>
					* ยอดเทิร์นที่ต้องทำคือ{' '}
					<strong>{format(tx.totalTurn)}</strong>
				</span>
				<h1>฿ {format(tx.amount)}</h1>
				<small>{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</small>
			</div>
		</div>
	</div>
)

interface OtherListProps {
	data: Transaction[]
}
const OtherList: FC<OtherListProps> = ({ data }) => {
	if (data.length <= 0) {
		return (
			<div className="notice__list">
				<p className="warning">
					<i className="fad fa-exclamation-square" />
					<span>คุณไม่มีรายการอื่น ๆ</span>
				</p>
			</div>
		)
	}

	return (
		<>
			{data.map((tx) => {
				if (tx.type === 'promotion') {
					return <PromotionListItem tx={tx} key={tx.id} />
				}
				return (
					<div className="history" key={tx.id}>
						<div className="history__type">
							<h1>{getTxTypeText(tx.type)}</h1>
						</div>
						<div className="history__amount">
							<div className="history__amount--status">
								<h1>฿ {format(tx.amount)}</h1>
								<small>
									{moment(tx.createdAt).format(
										'DD/MM/YYYY HH:mm'
									)}
								</small>
							</div>
						</div>
					</div>
				)
			})}
		</>
	)
}

export default OtherList
