import classNames from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { ApplicationState } from '../../store'
import BottomBar from './BottomBar'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import TopbarMobile from './TopbarMobile'

const AppLayout: FC = ({ children }) => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const loc = useLocation()

	return (
		<>
			<Sidebar prefix={prefixState.info} />

			{/* Topbar */}
			{loc.pathname !== '/wallet' && (
				<TopbarMobile pathname={loc.pathname} />
			)}
			<BottomBar member={userState.info} />

			<div
				className={classNames('main', {
					'main--space-y': loc.pathname !== '/wallet',
				})}
			>
				<Topbar
					prefix={prefixState.info}
					member={userState.info}
					pathname={loc.pathname}
				/>

				{children}
			</div>
		</>
	)
}

export default AppLayout
