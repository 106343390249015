import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { makePath } from '../../helpers/path'
import AuthService from '../../services/auth'
import { ApplicationState } from '../../store'
import {
	requestUserDone,
	requestUserFailed,
} from '../../store/user/user.actions'

const AuthContainer: FC = ({ children }) => {
	const dispatch = useDispatch()
	const userState = useSelector((state: ApplicationState) => state.user)

	const requestAuthState = useCallback(async () => {
		try {
			const res = await AuthService.getMemberInfo()
			dispatch(requestUserDone(res))
		} catch {
			localStorage.removeItem('x-auth')
			dispatch(requestUserFailed())
		}
	}, [dispatch])
	useEffect(() => {
		requestAuthState()
	}, [requestAuthState])

	if (!userState.isLoaded) {
		return (
			<div className="center-container">
				<i className="fad fa-spinner fa-3x fa-spin" />
			</div>
		)
	}
	if (userState.isLoaded && !userState.info) {
		return <Redirect to={makePath('/login')} />
	}
	return <>{children}</>
}

export default AuthContainer
