import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { format } from '../../helpers/number'
import { makePath } from '../../helpers/path'
import AuthService from '../../services/auth'
import { setUser } from '../../store/user/user.actions'

interface TurnBadgeProps {
	isTurnover: boolean
	credit: number
	totalTurn: number
	currentTurnover: number
}
const TurnBadge: FC<TurnBadgeProps> = ({
	isTurnover,
	credit,
	totalTurn,
	currentTurnover,
}) => {
	if (isTurnover) {
		return (
			<div className="wallet__main--turnover">
				<span>ยอดเทิร์นโอเวอร์</span>
				<p>
					<strong>{format(currentTurnover)}</strong>{' '}
					<span>/{format(totalTurn)}</span>
				</p>
			</div>
		)
	}
	return (
		<div className="wallet__main--turnover">
			<span>ยอดเทิร์น</span>
			<p>
				<strong>{format(credit)}</strong>{' '}
				<span>/{format(totalTurn)}</span>
			</p>
		</div>
	)
}

interface BalanceWidgetProps {
	isTurnover: boolean
	credit: number
	totalTurn: number
	currentTurnover: number
}
const BalanceWidget: FC<BalanceWidgetProps> = ({
	isTurnover,
	credit,
	totalTurn,
	currentTurnover,
}) => {
	const dispatch = useDispatch()
	const [fetching, setFetching] = useState(false)

	const refetchCredit = async () => {
		if (fetching) {
			return
		}
		setFetching(true)

		try {
			const res = await AuthService.getMemberInfo(true)
			dispatch(setUser(res))
		} catch ({ message }) {
			toast.error(message)
		} finally {
			setFetching(false)
		}
	}

	return (
		<div className="wallet">
			<div className="wallet__main">
				<div className="wallet__main--amount">
					<span>
						ยอดเงินทั้งหมด{' '}
						{fetching ? (
							<i className="fas fa-spinner fa-spin" />
						) : (
							<i
								className="fas fa-redo"
								onClick={refetchCredit}
							/>
						)}
					</span>
					<h1>฿ {format(credit)}</h1>
				</div>
				<div className="wallet__main--bonus">
					<Link to={makePath('/affiliate')} className="btn btn--dark">
						รับเครดิต
						<span>ฟรี</span>
					</Link>
				</div>
				{totalTurn > 0 && (
					<TurnBadge
						isTurnover={isTurnover}
						credit={credit}
						totalTurn={totalTurn}
						currentTurnover={currentTurnover}
					/>
				)}
			</div>
		</div>
	)
}

export default BalanceWidget
