import React, { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import { Promotion } from '../../@types/prefix'
import api from '../../helpers/api'
import WalletPromotion from './WalletPromotion'

interface WalletPromotionContainerProps {
	promotions?: Promotion[]
}
const WalletPromotionContainer: FC<WalletPromotionContainerProps> = ({
	promotions,
}) => {
	const [acceptingBonus, setAcceptingBonus] = useState('')
	const acceptBonus = async (key: string) => {
		setAcceptingBonus(key)

		try {
			const res = await api.post<void, APIResponse>(
				`/member/promotions/${key}`
			)
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message)
		} finally {
			setAcceptingBonus('')
		}
	}

	return (
		<div className="wrapper wrapper--desktop mt-3">
			<div className="title-bar">
				<h1>โปรโมชั่น</h1>
			</div>
			{promotions?.map((promo) => (
				<WalletPromotion
					data={promo}
					acceptingBonus={acceptingBonus}
					acceptBonus={acceptBonus}
					key={promo.key}
				/>
			))}
		</div>
	)
}

export default WalletPromotionContainer
