import { useFormik } from 'formik'
import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { redeemDeposit } from '../../services/deposit'
import BaseButton from '../Base/BaseButton'

interface RedeemDialogProps {
	isOpen: boolean
	onClose: () => void
}
const RedeemDialog: FC<RedeemDialogProps> = ({ isOpen, onClose }) => {
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			redeemCode: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await redeemDeposit({
					code: values.redeemCode,
				})

				toast.success(res.message as string)
				onClose()
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<div className="dialog" style={{ display: isOpen ? 'block' : 'none' }}>
			<div className="dialog__wrapper">
				<div className="fal fa-times-circle button-close" onClick={() => !isSubmitting && onClose()} />
				<div className="dialog-header">
					<h1>เติมคูปอง</h1>
				</div>

				<form className="truewallet-qr-dialog-form" onSubmit={handleSubmit}>
					<fieldset className="form-group">
						<label>รหัสคูปอง</label>
						<input
							type="text"
							name="redeemCode"
							className="form-control"
							placeholder="กรอกรหัสคูปอง"
							defaultValue={values.redeemCode}
							required
							onChange={handleChange}
						/>
					</fieldset>
					<div className="btn-submit">
						<BaseButton type="submit" loading={isSubmitting}>
							เติมคูปอง
						</BaseButton>
					</div>
				</form>
			</div>
		</div>
	)
}

export default RedeemDialog
