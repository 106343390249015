import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { generateQRCodeURL } from '../../helpers/qrCode'
import { truewalletQRDeposit } from '../../services/deposit'
import BaseButton from '../Base/BaseButton'

interface TruewalletQRDialogProps {
	isOpen: boolean
	onClose: () => void
}
const TruewalletQRDialog: FC<TruewalletQRDialogProps> = ({ isOpen, onClose }) => {
	const [depositQRCode, setDepositQRCode] = useState('')
	const [accountName, setAccountName] = useState('')

	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			amount: 100,
		},
		onSubmit: async (values) => {
			try {
				const res = await truewalletQRDeposit({
					amount: parseFloat(values.amount.toString()),
				})

				setDepositQRCode(res.data.qrCode)
				setAccountName(res.data.accountName)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	// watch open state
	useEffect(() => {
		if (isOpen) {
			setDepositQRCode('')
		}else{
			values.amount = 100
		}
	}, [isOpen,values])

	return (
		<div className="dialog" style={{ display: isOpen ? 'block' : 'none' }}>
			<div className="dialog__wrapper">
				<div className="fal fa-times-circle button-close" onClick={() => !isSubmitting && onClose()} />
				<div className="dialog-header">
					<h1>เติมเงิน</h1>
					<h6>
						{depositQRCode
							? 'กรุณาสแกน QR Code ด้วยแอพทรูมันนี่วอลเล็ทเพื่อเติมเงิน'
							: 'กรุณากรอกจำนวนเงินที่ต้องการเติม'}
					</h6>
				</div>

				{depositQRCode ? (
					<div className="truewallet-qr-deposit">
						<img src={generateQRCodeURL(depositQRCode)} alt="QR Code" />
						<h5>{accountName}</h5>
					</div>
				) : (
					<form className="truewallet-qr-dialog-form" onSubmit={handleSubmit}>
						<fieldset className="form-group">
							<label>จำนวนเงินที่ต้องการเติม</label>
							<input
								type="number"
								name="amount"
								className="form-control"
								placeholder="จำนวนเงินที่ต้องการเติม"
								min={50}
								value={values.amount}
								defaultValue={values.amount}
								required
								onChange={handleChange}
							/>
						</fieldset>
						<div className="btn-submit">
							<BaseButton type="submit" loading={isSubmitting}>
								เติมเงิน
							</BaseButton>
						</div>
					</form>
				)}
			</div>
		</div>
	)
}

export default TruewalletQRDialog
