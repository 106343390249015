export const bankNames: { [key: string]: string } = {
	KBANK: 'ธนาคารกสิกรไทย',
	BBL: 'ธนาคารกรุงเทพ',
	KTB: 'ธนาคารกรุงไทย',
	BAY: 'ธนาคารกรุงศรีอยุธยา',
	SCB: 'ธนาคารไทยพาณิชย์',
	KK: 'ธนาคารเกียรตินาคิน',
	CITI: 'ธนาคารซิตี้แบ้งค์',
	CIMB: 'ธนาคารซีไอเอ็มบี (ไทย)',
	TTB: 'ธนาคารทหารไทยธนชาต',
	TISCO: 'ธนาคารทิสโก้',
	BAAC: 'ธ.ก.ส.',
	UOBT: 'ธนาคารยูโอบี',
	LH: 'ธนาคารแลนด์ แอนด์ เฮ้าส',
	SC: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
	BOC: 'ธนาคารแห่งประเทศจีน (ไทย)',
	BOFA: 'ธนาคารแห่งอเมริกาฯ',
	GSB: 'ธนาคารออมสิน',
	GH: 'ธนาคารอาคารสงเคราะห์',
	IBANK: 'ธนาคารอิสลามแห่งประเทศไทย',
	ICBC: 'ธนาคารไอซีบีซี (ไทย)',
	HSBC: 'ธนาคารฮ่องกงและเซี่ยงไฮ้',
}
export const bankColors: { [key: string]: string } = {
	KBANK: '#138f2d',
	BBL: '#1e4598',
	KTB: '#1ba5e1',
	BAY: '#fec43b',
	SCB: '#4e2e7f',
	KK: '#199cc5',
	CITI: '#1583c7',
	CIMB: '#7e2f36',
	TTB: '#1279be',
	TISCO: '#12549f',
	BAAC: '#4b9b1d',
	UOBT: '#0b3979',
	LH: '#6d6e71',
	SC: '#0f6ea1',
	BOC: '#c50f1c',
	BOFA: '#e11e3c',
	GSB: '#eb198d',
	GH: '#f57d23',
	IBANK: '#184615',
	ICBC: '#c50f1c',
	HSBC: '#fd0d1b',
}

export const getBankName = (code: string = 'UNKNOWN') => {
	code = code.toUpperCase()
	if (code === 'TRUEWALLET') {
		return 'ทรูมันนี่วอลเล็ท'
	}
	if (bankNames.hasOwnProperty(code)) {
		return bankNames[code]
	}
	return code
}
export const getBankColor = (code: string = 'UNKNOWN') => {
	code = code.toUpperCase()
	if (bankColors.hasOwnProperty(code)) {
		return bankColors[code]
	}
	return '#fff'
}
