import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { format } from '../../helpers/number'
import { requestUser } from '../../store/user/user.actions'
import BaseButton from '../Base/BaseButton'

interface CashbackWidgetProps {
	cashback: number
}
const CashbackWidget: FC<CashbackWidgetProps> = ({ cashback }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const acceptCashback = async () => {
		setLoading(true)

		try {
			const res = await api.post<void, APIResponse>('/member/cashback')

			dispatch(requestUser())
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="wrapper wrapper--cashback">
			<BaseButton
				color="secondary"
				loading={loading}
				onClick={acceptCashback}>
				รับแคชแบ็ก ฿ {format(cashback)}
			</BaseButton>
		</div>
	)
}

export default CashbackWidget
