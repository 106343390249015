import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import api from '../helpers/api'
import { ApplicationState } from '../store'
import { requestUser } from '../store/user/user.actions'

interface WheelResponse {
	index: number
	reward: string
}
const LuckyWheel = () => {
	const dispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const wheel = useRef<any>()
	const isMounted = useRef(false)
	const active = useRef(false)
	const [loading, setLoading] = useState(false)
	const [reward, setReward] = useState('')

	const triggerLuckyWheel = async () => {
		setLoading(true)

		try {
			const res = await api.post<void, WheelResponse>('/member/wheel')

			const diff = Math.floor(Math.random() * (28 - 6) + 6)
			wheel.current.animation.stopAngle = 36 * res.index - diff
			wheel.current.animation.spins = 8
			wheel.current.stopAnimation(true)
			wheel.current.rotationAngle = 0
			wheel.current.draw()
			wheel.current.startAnimation()

			active.current = true
			setReward(res.reward)
		} catch ({ message }) {
			setLoading(false)
			toast.error(message)
		}
	}
	const luckyWheelCallback = useCallback(() => {
		if (active.current) {
			active.current = false
			setLoading(false)

			dispatch(requestUser())

			const rewardModal = document.getElementById('reward')
			if (rewardModal) {
				rewardModal.style.display = 'block'
			}
		}
	}, [active, dispatch])
	const closeRewardModal = () => {
		const rewardModal = document.getElementById('reward')
		if (rewardModal) {
			rewardModal.style.display = 'none'
		}
	}
	useEffect(() => {
		if (isMounted.current) {
			return
		}

		isMounted.current = true
		wheel.current = new window.Winwheel({
			numSegments: 10,
			outerRadius: 150,
			drawMode: 'image',
			drawText: true,
			textFontSize: 0,
			responsive: true,
			animation: {
				type: 'spinToStop',
				duration: 5,
				spins: 8,
				callbackFinished: () => {
					luckyWheelCallback()
				},
			},
		})

		const loadedImage = new Image()
		loadedImage.onload = () => {
			wheel.current.wheelImage = loadedImage
			wheel.current.draw()
		}
		loadedImage.src = prefixState.info?.wheel.resources['reward'] || ''
	}, [prefixState, luckyWheelCallback])

	if (!prefix || !prefix.wheel.enable) {
		return <div>ไม่สามารถใช้งานกงล้อเสี่ยงโชคได้ในขณะนี้</div>
	}
	return (
		<div className="lucky-wheel">
			<div className="lucky-wheel__frame">
				<img
					src={prefix.wheel.resources['background']}
					className="animate-form"
					alt="background"
				/>
				<img
					src={prefix.wheel.resources['background2']}
					className="animate-to"
					alt="background-2"
				/>
			</div>

			<div className="lucky-wheel__wheel">
				<canvas id="canvas" width="516" height="516">
					<p>
						Sorry, your browser doesn't support canvas. Please try
						another.
					</p>
				</canvas>
			</div>

			<div className="lucky-wheel__arrow">
				<img src={prefix.wheel.resources['arrow']} alt="arrow" />
			</div>
			<div className="lucky-wheel__logo">
				<img
					src={prefix.wheel.resources['logoWheel']}
					className="lucky-wheel__logo--bg"
					alt="logo-wheel"
				/>
				<img
					src={prefix.wheel.resources['logo']}
					className="logo-wheel"
					alt="logo"
				/>
			</div>
			<div className="lucky-wheel__play">
				{loading ? (
					<img src={prefix.wheel.resources['spinOff']} alt="play" />
				) : (
					<img
						src={prefix.wheel.resources['spinOn']}
						alt="play"
						onClick={triggerLuckyWheel}
					/>
				)}
			</div>

			<div id="reward" className="reward-modal">
				<div className="reward-modal__wrapper">
					<p>ยินดีด้วยคุณได้รับ</p>
					<h1>{reward}</h1>
					<div
						className="btn btn--primary"
						onClick={closeRewardModal}>
						รับรางวัล
					</div>
				</div>
			</div>
		</div>
	)
}

export default LuckyWheel
