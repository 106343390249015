import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getBankName } from '../helpers/bank'
import { ApplicationState } from '../store'

const Profile = () => {
	const userState = useSelector((state: ApplicationState) => state.user)
	const info = userState.info

	if (!info) {
		return <div>ไม่พบโปรไฟล์ของคุณในขณะนี้</div>
	}

	const copyGameUsername = () => {
		navigator.clipboard.writeText(
			info.game.meta.applicationUsername || info.game.username || 'msn.bet'
		)
		toast.success('คัดลอกชื่อผู้ใช้งานสำเร็จ')
	}
	const copyGamePassword = () => {
		navigator.clipboard.writeText(info.game.password || 'msn.bet')
		toast.success('คัดลอกรหัสผ่านสำเร็จ')
	}
	return (
		<div className="wrapper">
			<div className="profile">
				<div className="title-bar title-bar--sub-title">ข้อมูลเบื้องต้น</div>
				<div className="profile__col profile__col--order-xs-1">
					<h2>ข้อมูลส่วนตัว</h2>
					<p>
						<i className="fad fa-user" /> {info.firstName} {info.lastName}
					</p>
					<p>
						<i className="fad fa-phone" /> {info.phone}
					</p>

					<Link to="/logout" className="btn btn--danger btn--logout">
						<span>ออกจากระบบ</span>
					</Link>
				</div>
				<div className="profile__col profile__col--order-xs-3">
					<h2>บัญชีผู้ใช้งาน</h2>
					<p>
						<i className="fad fa-user" />{' '}
						{info.game.meta.applicationUsername || info.game.username}
						<span className="btn-copy" onClick={copyGameUsername}>
							<i className="fad fa-copy" /> คัดลอก
						</span>
					</p>
					<p>
						<i className="fad fa-lock" /> {info.game.password}
						<span className="btn-copy" onClick={copyGamePassword}>
							<i className="fad fa-copy" /> คัดลอก
						</span>
					</p>
				</div>
			</div>
			<div className="profile">
				<div className="title-bar title-bar--sub-title">
					<h1>ข้อมูลบัญชีธนาคาร</h1>
				</div>
				<div className="profile__col profile__col--order-xs-1">
					<h2>ธนาคาร</h2>
					<p className="highlight">{getBankName(info.bankCode)}</p>
					<h2 className="mt-1">เลขที่บัญชี</h2>
					<p className="highlight">{info.bankNumber}</p>
				</div>
				<div className="profile__col profile__col--order-xs-1">
					<h2>ชื่อบัญชี</h2>
					<p className="highlight">
						{info.firstName} {info.lastName}
					</p>
				</div>
			</div>
		</div>
	)
}

export default Profile
