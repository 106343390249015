import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Prefix } from '../../@types/prefix'
import { makePath } from '../../helpers/path'

interface SidebarProps {
	prefix?: Prefix
}
const Sidebar: FC<SidebarProps> = ({ prefix }) => (
	<div className="sidebar">
		<a href="#main" className="sidebar__logo">
			<img src={prefix?.logo} alt={prefix?.prefix} />
		</a>
		<ul>
			<li>
				<NavLink to={makePath('/wallet')} activeClassName="active-menu">
					<i className="fad fa-wallet" /> กระเป๋า
				</NavLink>
			</li>
			<li>
				<NavLink to={makePath('/deposit')} activeClassName="active-menu">
					<i className="fad fa-arrow-down" /> เติมเงิน
				</NavLink>
			</li>
			<li>
				<NavLink to={makePath('/withdraw')} activeClassName="active-menu">
					<i className="fad fa-arrow-up" /> ถอนเงิน
				</NavLink>
			</li>
			<li>
				<NavLink to={makePath('/transactions')} activeClassName="active-menu">
					<i className="fad fa-list-alt" /> ประวัติธุรกรรม
				</NavLink>
			</li>
			<li>
				<NavLink to={makePath('/promotion')} activeClassName="active-menu">
					<i className="fad fa-gift" /> โปรโมชั่น
				</NavLink>
			</li>
			{prefix?.wheel.enable && (
				<li>
					<NavLink to={makePath('/lucky-wheel')} activeClassName="active-menu">
						<i className="fad fa-life-ring" /> กงล้อเสี่ยงโชค
					</NavLink>
				</li>
			)}
			<li>
				<NavLink to={makePath('/affiliate')} activeClassName="active-menu">
					<i className="fad fa-sitemap" /> ชวนเพื่อนเล่น
				</NavLink>
			</li>
			<li>
				<NavLink to={makePath('/profile')} activeClassName="active-menu">
					<i className="fad fa-user" /> โปรไฟล์ของฉัน
				</NavLink>
			</li>
			{prefix?.lineLink && (
				<li>
					<a href={prefix.lineLink} target="_blank" rel="noreferrer">
						<i className="fab fa-line" /> แจ้งปัญหา
					</a>
				</li>
			)}
			<li>
				<NavLink to={makePath('/logout')}>
					<i className="fad fa-sign-out" /> ออกจากระบบ
				</NavLink>
			</li>
		</ul>
	</div>
)

export default Sidebar
