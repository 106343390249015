import classNames from 'classnames'
import React, { ButtonHTMLAttributes, FC } from 'react'

interface BaseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	color?: string
	loading?: boolean
}
const BaseButton: FC<BaseButtonProps> = ({
	color = 'primary',
	loading,
	children,
	...rest
}) => (
	<button
		{...rest}
		className={classNames('btn', `btn--${color}`)}
		disabled={loading}>
		{loading ? <i className="fad fa-spinner fa-spin" /> : children}
	</button>
)

export default BaseButton
