export const topbarText: { [key: string]: string } = {
	'/deposit': 'เติมเงิน',
	'/deposit/bank': 'โอนผ่านธนาคาร',
	'/deposit/wallet': 'ทรูมันนี่วอลเล็ท',
	'/withdraw': 'ถอนเงิน',
	'/transactions': 'ประวัติธุรกรรม',
	'/promotion': 'โปรโมชั่น',
	'/lucky-wheel': 'กงล้อเสี่ยงโชค',
	'/affiliate': 'ชวนเพื่อนเล่น',
	'/profile': 'โปรไฟล์ของฉัน',
	'/launch-game': 'เข้าเล่นเกม',
}

export const txTypeText: { [key: string]: string } = {
	deposit: 'เติมเงิน',
	withdraw: 'ถอนเงิน',
	promotion: 'รับโบนัส',
	cashback: 'รับแคชแบ็ก',
	wheel: 'กงล้อเสี่ยงโชค',
	add: 'เติมเครดิต',
	deduct: 'ตัดเครดิต',
	affiliate_transfer: 'โยกยอดเชิญเพื่อน',
	'new-reg-bonus': 'เครดิตสมาชิกใหม่',
}
