import React, { FC } from 'react'
import { Promotion } from '../../@types/prefix'
import BaseButton from '../Base/BaseButton'

interface WalletPromotionProps {
	data: Promotion

	acceptingBonus: string
	acceptBonus: (key: string) => void
}
const WalletPromotion: FC<WalletPromotionProps> = ({
	data,
	acceptingBonus,
	acceptBonus,
}) => (
	<div className="promo">
		<div className="promo__img">
			<img src={data.image} alt={data.name} />
		</div>
		<div className="promo__info promo__home">
			<div className="promo__info--text">
				<h1>{data.name}</h1>
				<p>{data.description}</p>
			</div>
			{data.amount > 0 && (
				<BaseButton
					type="button"
					loading={acceptingBonus === data.key}
					onClick={() => acceptBonus(data.key)}>
					รับโบนัส
				</BaseButton>
			)}
		</div>
	</div>
)

export default WalletPromotion
