import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { Prefix } from '../../@types/prefix'
import { AskmebetConfig } from '../../entities/askmebet'
import api from '../../helpers/api'
import AskmebetCasino from './Askmebet/Casino'
import AskmebetSlot from './Askmebet/Slot'

interface AskmebetProps {
	prefix: Prefix
	member: Member
}
const Askmebet: FC<AskmebetProps> = ({ prefix, member }) => {
	const [config, setConfig] = useState<AskmebetConfig | undefined>(undefined)
	const initAskmebetConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AskmebetConfig>('/askmebet')

			setConfig(res)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}, [])
	useEffect(() => {
		initAskmebetConfig()
	}, [initAskmebetConfig])

	if (!config) {
		return <div>Loading ...</div>
	}

	const casinoConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'casino')?.enable : true
	const slotConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'slot')?.enable : true

	const dynamicEnabledCasino = Object.values(config.casino.config).filter((v) => v.enable).length > 0
	const dynamicEnabledSlot = Object.values(config.slot.config).filter((v) => v.enable).length > 0

	return (
		<div className="wrapper">
			{casinoConfig && dynamicEnabledCasino && member.game.meta['casino'] !== 'no' && (
				<Fragment>
					<div className="title-bar title-bar--sub-title">
						<h2>คาสิโนออนไลน์</h2>
					</div>
					<AskmebetCasino config={config.casino.config} />
				</Fragment>
			)}

			{slotConfig && dynamicEnabledSlot && member.game.meta['slot'] !== 'no' && (
				<Fragment>
					<div className="title-bar title-bar--sub-title">
						<h2>สล็อตออนไลน์</h2>
					</div>
					<AskmebetSlot config={config.slot.config} games={config.slot.games} />
				</Fragment>
			)}
		</div>
	)
}

export default Askmebet
