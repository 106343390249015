import React, { FC, MouseEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { AskmebetGameProviderConfig, AskmebetLoginResponse } from '../../../entities/askmebet'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'

interface AskmebetCasinoCardProps {
	id: string

	login: (provider: string) => (e: MouseEvent<any>) => void
}
const AskmebetCasinoCard: FC<AskmebetCasinoCardProps> = ({ id, login }) => (
	<a href={`#${id}`} className="casino-card" onClick={login(id)}>
		<div className="casino-card__img">
			<div className="bg-card">
				<img src={`/casino-game/bg-casino-${id}.png`} alt={id} />
			</div>
			<img src={`/casino-game/women-casino-${id}.png`} className="women-card" alt={id} />
		</div>
		<div className="casino-card__logo">
			<img src={`/casino-game/logo-casino-${id}.png`} alt={id} />
		</div>
	</a>
)

interface AskmebetCasinoProps {
	config: Record<string, AskmebetGameProviderConfig>
}
const AskmebetCasino: FC<AskmebetCasinoProps> = ({ config }) => {
	const [redirecting, setRedirecting] = useState(false)
	const login = (provider: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()

		setRedirecting(true)
		try {
			const res = await api.post<void, AskmebetLoginResponse>('/askmebet/login/casino', {
				provider: provider,
				isMobile: isMobile(),
			})

			window.location.href = res.url
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setRedirecting(false)
		}
	}

	if (redirecting) {
		return null
	}
	return (
		<div className="casino-wrapper">
			{Object.values(config)
				.filter((provider) => provider.enable)
				.map((provider) => (
					<AskmebetCasinoCard key={provider.id} id={provider.id} login={login} />
				))}
		</div>
	)
}

export default AskmebetCasino
