import React from 'react'
import { useSelector } from 'react-redux'
import BankInfoWidget from '../components/Withdraw/BankInfoWidget'
import { ApplicationState } from '../store'

const Withdraw = () => {
	const userState = useSelector((state: ApplicationState) => state.user)
	const memberInfo = userState.info

	if (!memberInfo) {
		return <div>Undefined member</div>
	}
	return (
		<div className="wrapper">
			<BankInfoWidget credit={userState.credit} info={memberInfo} />
		</div>
	)
}

export default Withdraw
