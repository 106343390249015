import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Transaction } from '../@types/transaction'
import DepositList from '../components/Transaction/DepositList'
import OtherList from '../components/Transaction/OtherList'
import WithdrawList from '../components/Transaction/WithdrawList'
import api from '../helpers/api'

const Transactions = () => {
	const [transactions, setTransactions] = useState<Transaction[]>([])
	const initTransactionsRequest = useCallback(async () => {
		try {
			const res = await api.get<void, Transaction[]>(
				'/member/transactions'
			)

			setTransactions(res)
		} catch ({ message }) {
			toast.error(message)
		}
	}, [])
	useEffect(() => {
		initTransactionsRequest()
	}, [initTransactionsRequest])

	const [tab, setTab] = useState('deposit')
	return (
		<div className="wrapper">
			<div className="tab mb-5">
				<div
					className={classNames('tab__link', {
						'tab-active': tab === 'deposit',
					})}
					onClick={() => setTab('deposit')}>
					เติมเงิน
				</div>
				<div
					className={classNames('tab__link', {
						'tab-active': tab === 'withdraw',
					})}
					onClick={() => setTab('withdraw')}>
					ถอนเงิน
				</div>
				<div
					className={classNames('tab__link', {
						'tab-active': tab === 'other',
					})}
					onClick={() => setTab('other')}>
					อื่น ๆ
				</div>
			</div>

			<div className="tab__content">
				{tab === 'deposit' && (
					<DepositList
						data={transactions.filter(
							(tx) => tx.type === 'deposit'
						)}
					/>
				)}
				{tab === 'withdraw' && (
					<WithdrawList
						data={transactions.filter(
							(tx) => tx.type === 'withdraw'
						)}
					/>
				)}
				{tab === 'other' && (
					<OtherList
						data={transactions.filter(
							(tx) =>
								tx.type !== 'deposit' && tx.type !== 'withdraw'
						)}
					/>
				)}
			</div>
		</div>
	)
}

export default Transactions
