import axios, { AxiosError } from 'axios'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
const baseRequest = axios.create({
	baseURL: API_ENDPOINT,
	withCredentials: true,
})

baseRequest.interceptors.request.use((config) => {
	const prefix = localStorage.getItem('x-prefix')
	const token = localStorage.getItem('x-auth')

	return {
		...config,
		headers: {
			...config.headers,
			...(prefix && { 'X-Prefix': prefix }),
			...(token && { Authorization: `Bearer ${token}` }),
		},
	}
})

baseRequest.interceptors.response.use(
	(res) => res.data,
	(err: AxiosError) => {
		if (err.response) {
			return Promise.reject(err.response.data)
		}

		if (API_ENDPOINT?.indexOf('v2.apimsn.com') !== -1) {
			const investigateKey = 'nextInvestigate'
			const nextInvestigateTimestamp = window.localStorage.getItem(investigateKey)
			if (nextInvestigateTimestamp && parseInt(nextInvestigateTimestamp) > Date.now()) {
				return Promise.reject(err.message)
			}

			const delayMS = 15 * 60 * 1000
			window.localStorage.setItem(investigateKey, (Date.now() + delayMS).toString())

			const rdr = encodeURIComponent(window.location.href)
			window.location.href = `https://v2.apimsn.com/investigate?rdr=${rdr}`
			return Promise.reject({ message: 'กำลังพาคุณไปยังหน้ายืนยันตัวตน' })
		}
		return Promise.reject({
			message: 'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้',
		})
	}
)

export default baseRequest
