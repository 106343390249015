import { useFormik } from 'formik'
import React, { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import BaseButton from '../components/Base/BaseButton'
import api from '../helpers/api'

interface AMBPayResponse {
	qrCode: string
}

const AMBPay = () => {
	const [qrCode, setQRCode] = useState('')
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			amount: 200,
		},
		onSubmit: async (values) => {
			try {
				const res = await api.post<void, AMBPayResponse>('/member/ambpay', {
					amount: parseFloat(values.amount.toString()),
				})

				setQRCode(res.qrCode)
			} catch ({ message }) {
				toast.error(message)
			}
		},
	})

	return (
		<Fragment>
			<div className="profile">
				<div className="title-bar title-bar--sub-title">
					<h1>รายละเอียดการเติมเงิน</h1>
				</div>
				<div className="profile__col profile__col--withdraw">
					<h2>ยอดเติมเงินขั้นต่ำ</h2>
					<p className="highlight">฿ 200</p>
				</div>
				<div className="profile__col profile__col--withdraw xs-right">
					<h2>ยอดเติมเงินสูงสุด</h2>
					<p className="danger">฿ 500,000</p>
				</div>
				<div className="profile__col--form">
					<form onSubmit={handleSubmit}>
						<fieldset className="form-group">
							<label>จำนวนเงินที่ต้องการเติม</label>
							<input
								type="number"
								name="amount"
								className="form-control"
								placeholder="จำนวนเงินที่ต้องการเติม"
								value={values.amount}
								required
								onChange={handleChange}
							/>
						</fieldset>
						<div className="btn-submit">
							<BaseButton type="submit" loading={isSubmitting}>
								ยืนยันเติมเงิน
							</BaseButton>
						</div>
					</form>
				</div>
			</div>

			<div className="dialog" style={{ display: qrCode ? 'block' : 'none' }}>
				<div className="dialog__wrapper">
					<div className="fal fa-times-circle button-close" onClick={() => setQRCode('')} />
					<div className="dialog-header">
						<h1>เติมเงิน</h1>
						<h6>ชำระเงินผ่าน QR Code ด้านล่างเพื่อดำเนินการเติมเงิน</h6>
					</div>

					<div className="ambpay-qrcode">
						<img src={qrCode} alt="QR Code" />
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default AMBPay
