import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'

interface WalletMobileMenuProps {
	lineLink: string
	wheel: boolean
}
const WalletMobileMenu: FC<WalletMobileMenuProps> = ({ lineLink, wheel }) => (
	<div className="wrapper wrapper--mobile mt-2 pb-3">
		<Link to={makePath('/deposit')} className="menu-mobile">
			<div className="menu-mobile__icon">
				<i className="fad fa-arrow-down" />
			</div>
			เติมเงิน
		</Link>
		<Link to={makePath('/withdraw')} className="menu-mobile">
			<div className="menu-mobile__icon">
				<i className="fad fa-arrow-up" />
			</div>
			ถอนเงิน
		</Link>
		<Link to={makePath('/promotion')} className="menu-mobile">
			<div className="menu-mobile__icon">
				<i className="fad fa-gift" />
			</div>
			โปรโมชั่น
		</Link>
		<Link to={makePath('/transactions')} className="menu-mobile">
			<div className="menu-mobile__icon">
				<i className="fad fa-list-alt" />
			</div>
			ประวัติ
		</Link>
		<Link to={makePath('/profile')} className="menu-mobile">
			<div className="menu-mobile__icon">
				<i className="fad fa-user" />
			</div>
			โปรไฟล์
		</Link>
		<Link to={makePath('/affiliate')} className="menu-mobile">
			<div className="menu-mobile__icon">
				<i className="fad fa-sitemap" />
			</div>
			ชวนเพื่อน
		</Link>
		{wheel && (
			<Link to={makePath('/lucky-wheel')} className="menu-mobile">
				<div className="menu-mobile__icon">
					<i className="fad fa-life-ring" />
				</div>
				กงล้อ
			</Link>
		)}
		{lineLink && (
			<a href={lineLink} target="_blank" rel="noreferrer" className="menu-mobile">
				<div className="menu-mobile__icon">
					<i className="fab fa-line" />
				</div>
				แจ้งปัญหา
			</a>
		)}
	</div>
)

export default WalletMobileMenu
