import QRCodeStyling from 'qr-code-styling'
import React, { FC, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'

interface AffiliateWidgetProps {
	member?: Member
	affiliateCode: string
	affiliateLink: string
}
const AffiliateWidget: FC<AffiliateWidgetProps> = ({
	member,
	affiliateCode,
	affiliateLink,
}) => {
	const copyAffiliateLink = () => {
		navigator.clipboard.writeText(affiliateLink)
		toast.success('คัดลอกลิงก์ชวนเพื่อนสำเร็จ')
	}

	const qrRef = useRef(null)
	const [qrRender, setQrRender] = useState(false)
	useEffect(() => {
		if (!qrRender && qrRef.current) {
			const qrCode = new QRCodeStyling({
				width: 100,
				height: 100,
				backgroundOptions: {
					color: '#fff',
				},
				data: affiliateLink,
			})

			qrCode.append(qrRef.current!)
			setQrRender(true)
		}
	}, [qrRender, affiliateLink])

	return (
		<div className="affiliate">
			<div className="affiliate__qrcode" ref={qrRef} />
			<div className="affiliate__info">
				<h1>ลิงก์สำหรับชวนเพื่อน</h1>
				<div className="affiliate__info--group">
					<span>รหัสชวนเพื่อน</span>
					<strong>{affiliateCode}</strong>
				</div>
				<div className="affiliate__info--group">
					<span>
						ลิงก์ชวนเพื่อน {'  '}
						<i
							className="far fa-copy"
							onClick={copyAffiliateLink}
						/>
					</span>
					<a href="#aff">{affiliateLink}</a>
				</div>
			</div>
		</div>
	)
}

export default AffiliateWidget
