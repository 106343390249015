import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'

interface PGSlotResponse {
	token: string
	url: string
}
const PGSlotGame = () => {
	const history = useHistory()
	const initPGSlotGame = useCallback(async () => {
		try {
			const { token, url } = await api.post<void, PGSlotResponse>('/login/pgslot')

			if (url) {
				window.location.href = url
			} else {
				window.location.href = `https://pgslot.co/games/index.html?t=${token}`
			}
		} catch ({ message }) {
			toast.error(message)
			history.push(makePath('/wallet'))
		}
	}, [history])
	useEffect(() => {
		initPGSlotGame()
	}, [initPGSlotGame])

	return <div>Redirecting ...</div>
}

export default PGSlotGame
