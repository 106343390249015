import React, { ComponentType, FC } from 'react'
import store from '../../store'

const AuthLayout = (Component: ComponentType<object>): FC => (props) => {
	const prefixState = store.getState().prefix

	return (
		<div className="container">
			<div className="auth-wrapper">
				<div className="logo text-center">
					<img
						src={prefixState.info?.logo}
						alt={prefixState.info?.prefix}
						width="125"
					/>
				</div>
				<Component {...props} />
			</div>
		</div>
	)
}

export default AuthLayout
