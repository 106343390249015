import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import WalletDepositItem from '../components/Deposit/WalletDepositItem'
import { ApplicationState } from '../store'

const WalletDeposit = () => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)

	const depositToTruewallets = depositToList.filter((bank) => bank.bank === 'truewallet')
	if (depositToTruewallets.length <= 0) {
		return <Redirect to="/deposit" />
	}
	return (
		<div className="wrapper">
			<div className="notice">
				<div className="notice__list">
					<p className="alert">
						<i className="fad fa-bullhorn" />
						<span>
							ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน &nbsp;
							<strong>ภายใน 30 วินาที</strong>
						</span>
					</p>
				</div>
				<div className="notice__list">
					<p className="warning">
						<i className="fad fa-engine-warning" />
						<span>
							สามารถโอนเงินเข้าธนาคารโดยใช้ &nbsp;
							<strong>ชื่อบัญชีและเบอร์โทรศัพท์</strong>
							&nbsp; ที่สมัครโอนเข้ามาเท่านั้น
						</span>
					</p>
				</div>
			</div>

			{depositToTruewallets.map((bank) => (
				<WalletDepositItem bank={bank} key={bank.accountNumber} />
			))}
		</div>
	)
}

export default WalletDeposit
