import React, { ChangeEvent, FC } from 'react'
import BaseButton from '../Base/BaseButton'
import NumberFormat from 'react-number-format';

interface RegisterStep1Props {
	isSubmitting: boolean

	handleChange: (e: ChangeEvent<any>) => void
}
const RegisterStep1: FC<RegisterStep1Props> = ({
	isSubmitting,
	handleChange,
}) => (
	<>
		<fieldset className="form-group">
			<label>เบอร์โทรศัพท์</label>
			<NumberFormat
				format="###-###-####"
				mask="_"
				type="tel"
				name="phone"
				className="form-control form-control-lg"
				placeholder="เบอร์โทรศัพท์"
				required
				onChange={handleChange}
			/>
		</fieldset>
		<fieldset className="form-group">
			<BaseButton type="submit" loading={isSubmitting}>
				ถัดไป
			</BaseButton>
		</fieldset>
	</>
)

export default RegisterStep1
