import { useFormik } from 'formik'
import qs from 'querystring'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import RegisterStep1 from '../components/Register/RegisterStep1'
import RegisterStep2 from '../components/Register/RegisterStep2'
import RegisterStep3 from '../components/Register/RegisterStep3'
import api from '../helpers/api'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { ApplicationState } from '../store'
import { setUser } from '../store/user/user.actions'

interface RegisterResponse {
	token: string
}
const Register = () => {
	const dispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const location = useLocation()
	const query = qs.parse(location.search.substr(1))
	const refCode = query.ref ? query.ref.toString() : ''

	let autoBonus = 'off'
	if (refCode && prefix) {
		const promotion = prefix.promotions.find((v) => v.limit === 'once')
		if (promotion) {
			autoBonus = promotion.key
		}
	}

	const [step, setStep] = useState(1)
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			phone: '',
			bankCode: '',
			bankNumber: '',
			firstName: '',
			lastName: '',
			firstNameEN: '',
			lastNameEN: '',
			password: '',
			line: '',
			autoBonus: autoBonus,
			knowFrom: refCode !== '' ? 'friend' : '',
			affiliateCode: refCode,
			captcha: '',
		},
		onSubmit: async (values, { setSubmitting }) => {
			values.phone = values.phone.replace(/-|_/g, '')
			if (step === 1) {
				if(values.phone.length !== 10){
					toast.error('เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร')
					return
				}
				try {
					await api.post('/register/step-1', values)

					setStep(2)
				} catch ({ message }) {
					toast.error(message)
				} finally {
					setSubmitting(false)
				}
			} else if (step === 2) {
				const thaiRegex = /^[ก-๙ ]+$/g
				if (!values.firstName.trim().match(thaiRegex)) {
					toast.error('ชื่อต้องเป็นภาษาไทยเท่านั้น')
					return
				}
				if (!values.lastName.trim().match(thaiRegex)) {
					toast.error('นามสกุลต้องเป็นภาษาไทยเท่านั้น')
					return
				}
				if (values.bankCode === '') {
					toast.error('กรุณาเลือกธนาคาร')
					return
				}

				try {
					await api.post('/register/step-2', values)

					setStep(3)
				} catch ({ message }) {
					toast.error(message)
				} finally {
					setSubmitting(false)
				}
			} else if (step === 3) {
				if (values.password.length < 6) {
					toast.error('รหัสผ่านต้องมีความยาวมากกว่า 6 ตัวอักษร')
					return
				}

				const alphaRegex = /(?=.*[a-zA-Z])/g
				if (!values.password.match(alphaRegex)) {
					toast.error('รหัสผ่านต้องมีตัวอักษรอย่างน้อย 1 ตัว')
					return
				}

				const numberRegex = /(?=.*[0-9])/g
				if (!values.password.match(numberRegex)) {
					toast.error('รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว')
					return
				}

				try {
					const res = await api.post<void, RegisterResponse>('/register', values)

					AuthService.setToken(res.token)
					const member = await AuthService.getMemberInfo(true)
					dispatch(setUser(member))

					setStep(4)
				} catch ({ message }) {
					setSubmitting(false)
					toast.error(message)
				}
			}
		},
	})

	if (!prefix) {
		return <div>ไม่สามารถสมัครสมาชิกได้ในขณะนี้</div>
	}
	return (
		<>
			<form className="mt-1" onSubmit={handleSubmit}>
				{step === 1 && <RegisterStep1 isSubmitting={isSubmitting} handleChange={handleChange} />}
				{step === 2 && (
					<RegisterStep2 bankCode={values.bankCode} isSubmitting={isSubmitting} handleChange={handleChange} />
				)}
				{step === 3 && (
					<RegisterStep3
						autoBonus={values.autoBonus || 'off'}
						defaultKnowFrom={values.knowFrom}
						showAffiliateCode={values.knowFrom === 'friend'}
						defaultAffiliateCode={values.affiliateCode}
						promotions={prefix.promotions}
						isSubmitting={isSubmitting}
						handleChange={handleChange}
					/>
				)}
				{step === 4 && (
					<div className="success-section">
						<h4>สมัครสมาชิกสำเร็จแล้ว</h4>
						<h6>คุณสามารถใช้เบอร์โทรศัพท์และรหัสผ่านด้านล่างเพื่อเข้าสู่ระบบ</h6>

						<div>
							<h5>เบอร์โทรศัพท์: {values.phone}</h5>
							<h5 className="mb-0">รหัสผ่าน: {values.password}</h5>
						</div>

						<Link to={makePath('/wallet')} className="btn btn--primary">
							เข้าสู่หน้าหลัก
						</Link>
					</div>
				)}
			</form>
			<div className="text-center mt-1">
				<span>ต้องการเข้าสู่ระบบใช่ไหม? </span>
				<Link to={makePath('/login')}>เข้าสู่ระบบ</Link>
			</div>
		</>
	)
}

export default Register
