import React, { FC } from 'react'

interface StatusBadgeProps {
	status: string
}
const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
	if (status === 'pending') {
		return (
			<div className="history__amount--status wait">
				<span>รอดำเนินการ</span>
			</div>
		)
	}
	if (status === 'reject') {
		return (
			<div className="history__amount--status failed">
				<span>รายการไม่สำเร็จ</span>
			</div>
		)
	}
	return (
		<div className="history__amount--status success">
			<span>รายการสำเร็จ</span>
		</div>
	)
}

export default StatusBadge
