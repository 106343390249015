import { FC, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from '../../@types/store'
import history from '../../helpers/history'
import { ApplicationState } from '../../store'
import { requestPrefix } from '../../store/prefix/prefix.actions'

ReactGA.initialize('UA-172529548-1')

const PrefixContainer: FC = ({ children }) => {
	const dispatch: ThunkDispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)

	useEffect(() => {
		const unsub = history.listen((loc) => {
			ReactGA.pageview(loc.pathname + loc.search)
		})
		return () => {
			unsub()
		}
	}, [])

	useEffect(() => {
		dispatch(requestPrefix())
	}, [dispatch])

	useEffect(() => {
		if (prefixState.isLoaded && prefixState.info) {
			document.title = prefixState.info.title

			const icon = document.querySelector(`link[rel="icon"]`)
			if (icon) {
				icon.setAttribute('href', prefixState.info.logo)
			}
		}
	}, [prefixState])

	if (!prefixState.isLoaded) {
		return (
			<div className="center-container">
				<i className="fad fa-spinner fa-3x fa-spin" />
			</div>
		)
	}
	if (prefixState.isLoaded && !prefixState.info) {
		return (
			<div className="center-container">
				<i className="fad fa-engine-warning fa-3x" />
				<h3 className="error-h3">เว็บไซต์ดังกล่าวไม่สามารถใช้งานได้</h3>
				<h5 className="erorr-h5">กรุณาตรวจสอบข้อมูลอีกครั้ง</h5>
			</div>
		)
	}
	if (prefixState.isLoaded && prefixState.info?.maintenance) {
		return (
			<div className="center-container">
				<i className="fad fa-engine-warning fa-3x" />
				<h3 className="error-h3">เว็บไซต์อยู่ระหว่างการปรับปรุง</h3>
				<h5 className="erorr-h5">กรุณาติดต่อผู้ดูแลระบบ</h5>
			</div>
		)
	}
	return <>{children}</>
}

export default PrefixContainer
