import React, { FC } from 'react'
import { Member } from '../../@types/member'
import { getBankColor, getBankName } from '../../helpers/bank'
import WithdrawContainer from './WithdrawContainer'

interface BankInfoWidgetProps {
	credit: number
	info: Member
}
const BankInfoWidget: FC<BankInfoWidgetProps> = ({ credit, info }) => (
	<>
		<div className="profile">
			<div className="title-bar title-bar--sub-title">
				<h1>ถอนเงินเข้าบัญชีธนาคาร</h1>
			</div>
			<div className="profile__col--image profile__col--order-xs-2">
				<div
					className="bank-image"
					style={{ backgroundColor: getBankColor(info.bankCode) }}>
					<img
						src={
							require(`../../assets/banks/${info.bankCode.toLowerCase()}.svg`)
								.default
						}
						alt={getBankName(info.bankCode)}
					/>
				</div>
			</div>
			<div className="profile__col profile__col--order-xs-1">
				<h2>เลขที่บัญชี</h2>
				<p className="highlight">{info.bankNumber}</p>
				<p>{getBankName(info.bankCode)}</p>
			</div>
			<div className="profile__col profile__col--order-xs-3">
				<h2>ชื่อบัญชี</h2>
				<p>
					{info.firstName} {info.lastName}
				</p>
			</div>
		</div>

		<WithdrawContainer credit={credit} info={info} />
	</>
)

export default BankInfoWidget
