import React, { FC } from 'react'
import { Member } from '../../@types/member'
import { getBankName } from '../../helpers/bank'
import { format } from '../../helpers/number'
import AffiliateTransferForm from './AffiliateTransferForm'

interface AffiliateTransferContainerProps {
	member?: Member
}
const AffiliateTransferContainer: FC<AffiliateTransferContainerProps> = ({
	member,
}) => {
	return (
		<div className="profile">
			<div className="title-bar title-bar--sub-title">
				<h1>โยกเงินเชิญเพื่อน</h1>
			</div>
			<div className="profile__col profile__col--account">
				<div className="bank-info">
					<small>{getBankName(member?.bankCode)}</small>
					<h1>{member?.bankNumber}</h1>
					<p>
						{member?.firstName} {member?.lastName}
					</p>
				</div>
			</div>
			<div className="profile__col profile__col--withdraw">
				<h2>จำนวนเงินคงเหลือ</h2>
				<p className="highlight">
					฿ {format(member?.affiliate.balance || 0)}
				</p>
			</div>
			<div className="profile__col--form">
				<AffiliateTransferForm
					initAmount={member?.affiliate.balance || 0}
				/>
			</div>
		</div>
	)
}

export default AffiliateTransferContainer
