import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AuthContainer from './components/Container/AuthContainer'
import AppLayout from './components/Layout/AppLayout'
import { makePath } from './helpers/path'
import Affiliate from './views/Affiliate'
import AMBPay from './views/AMBPay'
import BankDeposit from './views/BankDeposit'
import Deposit from './views/Deposit'
import LaunchGame from './views/LaunchGame'
import Logout from './views/Logout'
import LuckyWheel from './views/LuckyWheel'
import Profile from './views/Profile'
import Promotion from './views/Promotion'
import Transactions from './views/Transactions'
import Wallet from './views/Wallet'
import WalletDeposit from './views/WalletDeposit'
import Withdraw from './views/Withdraw'

const App = () => {
	return (
		<AuthContainer>
			<AppLayout>
				<Switch>
					<Route path="/wallet" component={Wallet} />
					<Route exact path="/deposit" component={Deposit} />
					<Route exact path="/deposit/bank" component={BankDeposit} />
					<Route exact path="/deposit/wallet" component={WalletDeposit} />
					<Route exact path="/deposit/ambpay" component={AMBPay} />
					<Route path="/withdraw" component={Withdraw} />
					<Route path="/transactions" component={Transactions} />
					<Route path="/promotion" component={Promotion} />
					<Route path="/lucky-wheel" component={LuckyWheel} />
					<Route path="/affiliate" component={Affiliate} />
					<Route path="/profile" component={Profile} />
					<Route path="/launch-game" component={LaunchGame} />
					<Route path="/logout" component={Logout} />

					<Redirect to={makePath('/wallet')} />
				</Switch>
			</AppLayout>
		</AuthContainer>
	)
}

export default App
