import QRCodeStyling from 'qr-code-styling'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AffiliateTransferContainer from '../components/Affiliate/AffiliateTransferContainer'
import { ApplicationState } from '../store'

const Affiliate = () => {
	const userState = useSelector((state: ApplicationState) => state.user)
	const copyAffiliateLink = () => {
		navigator.clipboard.writeText(userState.affiliate.link)
		toast.success('คัดลอกลิงก์ชวนเพื่อนสำเร็จ')
	}

	const qrRef = useRef(null)
	const [qrRender, setQrRender] = useState(false)
	useEffect(() => {
		if (!qrRender && qrRef.current && userState.info) {
			const qrCode = new QRCodeStyling({
				width: 100,
				height: 100,
				backgroundOptions: {
					color: '#fff',
				},
				data: userState.affiliate.link,
			})

			qrCode.append(qrRef.current!)
			setQrRender(true)
		}
	}, [userState, qrRender])

	return (
		<div className="wrapper">
			<div className="profile">
				<div className="title-bar title-bar--sub-title">
					<h1>ข้อมูลสำหรับชวนเพื่อน</h1>
				</div>
				<div
					ref={qrRef}
					className="profile__col--image profile__col--order-xs-2"
				/>
				<div className="profile__col profile__col--order-xs-1">
					<h2>รหัสชวนเพื่อน</h2>
					<p className="highlight">{userState.affiliate.code}</p>
				</div>
				<div className="profile__col profile__col--order-xs-3 profile__col--affiliate">
					<h2>ลิงก์ชวนเพื่อน</h2>
					<p className="link">
						<a href="#aff">{userState.affiliate.link}</a>
						<span className="btn-copy" onClick={copyAffiliateLink}>
							<i className="fad fa-copy" />
							{'  '}
							คัดลอก
						</span>
					</p>
				</div>
			</div>

			<AffiliateTransferContainer member={userState.info} />
		</div>
	)
}

export default Affiliate
