import React from 'react'
import { useSelector } from 'react-redux'
import AffiliateWidget from '../components/Wallet/AffiliateWidget'
import BalanceWidget from '../components/Wallet/BalanceWidget'
import CashbackWidget from '../components/Wallet/CashbackWidget'
import WalletMobileMenu from '../components/Wallet/WalletMobileMenu'
import WalletPromotionContainer from '../components/Wallet/WalletPromotionContainer'
import { ApplicationState } from '../store'

const Wallet = () => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)

	return (
		<>
			<div className="wrapper wrapper-top">
				<BalanceWidget
					isTurnover={prefixState.info?.isTurnover || false}
					credit={userState.credit}
					totalTurn={userState.info?.totalTurn || 0}
					currentTurnover={userState.currentTurnover}
				/>

				<AffiliateWidget
					member={userState.info}
					affiliateCode={userState.affiliate.code}
					affiliateLink={userState.affiliate.link}
				/>
			</div>

			{userState.cashback.enable && <CashbackWidget cashback={userState.cashback.total} />}

			{/* Promotion */}
			{(prefixState.info?.promotions.length || 0) > 0 && (
				<WalletPromotionContainer promotions={prefixState.info?.promotions} />
			)}

			{/* Mobile menu */}
			<WalletMobileMenu
				lineLink={prefixState.info?.lineLink || ''}
				wheel={prefixState.info?.wheel.enable || false}
			/>
		</>
	)
}

export default Wallet
