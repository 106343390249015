import React, { FC } from 'react'
import { Promotion } from '../../@types/prefix'
import BaseButton from '../Base/BaseButton'

interface PromotionListItemProps {
	promotion: Promotion
	defaultAutoBonus: string
	acceptingBonus: string

	acceptBonus: (v: string) => void
	changeAutoBonus: (v: string) => void
}
const PromotionListItem: FC<PromotionListItemProps> = ({
	promotion,
	defaultAutoBonus,
	acceptingBonus,

	acceptBonus,
	changeAutoBonus,
}) => (
	<div className="promo promo--page">
		<div className="promo__img">
			<img src={promotion.image} alt={promotion.name} />
		</div>
		<div className="promo__info">
			<div className="promo__info--text">
				<h1>{promotion.name}</h1>
				<p>{promotion.description}</p>
			</div>
			{promotion.amount > 0 && (
				<>
					<label className="radio-group">
						<input
							type="radio"
							name="autoBonus"
							value={promotion.key}
							defaultChecked={defaultAutoBonus === promotion.key}
							onClick={() => changeAutoBonus(promotion.key)}
						/>
						<span>รับโบนัสอัตโนมัติ</span>
						<span className="checkmark" />
					</label>
					<BaseButton
						type="button"
						loading={acceptingBonus === promotion.key}
						onClick={() => acceptBonus(promotion.key)}>
						รับโบนัส
					</BaseButton>
				</>
			)}
		</div>
	</div>
)

export default PromotionListItem
