import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { DepositBank } from '../../@types/prefix'
import { getBankColor, getBankName } from '../../helpers/bank'

interface BankDepositItemProps {
	bank: DepositBank
}
const BankDepositItem: FC<BankDepositItemProps> = ({ bank }) => {
	const copyAccountNumber = () => {
		navigator.clipboard.writeText(bank.accountNumber)
		toast.success('คัดลอกเลขที่บัญชีสำเร็จ')
	}

	return (
		<div className="bank-info">
			<div
				className="bank-image"
				style={{ backgroundColor: getBankColor(bank.bank) }}>
				<img
					width={80}
					src={
						require(`../../assets/banks/${bank.bank.toLowerCase()}.svg`)
							.default
					}
					alt={getBankName(bank.bank)}
				/>
			</div>
			<small>{getBankName(bank.bank)}</small>
			<h1>{bank.accountNumber}</h1>
			<p>{bank.accountName}</p>
			<span className="btn-copy" onClick={copyAccountNumber}>
				<i className="fad fa-copy" />
				คัดลอก
			</span>
		</div>
	)
}

export default BankDepositItem
