import { useFormik } from 'formik'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { requestUser } from '../../store/user/user.actions'
import BaseButton from '../Base/BaseButton'

interface WithdrawFormProps {
	initAmount: number
}
const WithdrawForm: FC<WithdrawFormProps> = ({ initAmount }) => {
	const dispatch = useDispatch()
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			amount: initAmount,
		},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const res = await api.post<void, APIResponse>(
					'/member/withdraw',
					values
				)

				dispatch(requestUser())
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message)
			} finally {
				setSubmitting(false)
			}
		},
	})

	return (
		<form onSubmit={handleSubmit}>
			<fieldset className="form-group">
				<label>จำนวนเงินที่ต้องการถอน</label>
				<input
					type="number"
					name="amount"
					className="form-control"
					placeholder="จำนวนเงินที่ต้องการถอน"
					value={values.amount}
					required
					onChange={handleChange}
				/>
			</fieldset>
			<div className="btn-submit">
				<BaseButton type="submit" loading={isSubmitting}>
					ยืนยันถอนเงิน
				</BaseButton>
			</div>
		</form>
	)
}

export default WithdrawForm
