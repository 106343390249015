import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { DepositBank } from '../../@types/prefix'
import truewalletLogo from '../../assets/images/topup/truewallet.png'

interface WalletDepositItemProps {
	bank: DepositBank
}
const WalletDepositItem: FC<WalletDepositItemProps> = ({ bank }) => {
	const copyAccountNumber = () => {
		navigator.clipboard.writeText(bank.accountNumber)
		toast.success('คัดลอกเลขที่บัญชีสำเร็จ')
	}

	return (
		<div className="bank-info">
			<div className="bank-image" style={{ backgroundColor: '#fff' }}>
				<img width={80} src={truewalletLogo} alt="ทรูมันนี่วอลเล็ท" />
			</div>
			<small>ทรูมันนี่วอลเล็ท</small>
			<h1>{bank.accountNumber}</h1>
			<p>{bank.accountName}</p>
			<span className="btn-copy" onClick={copyAccountNumber}>
				<i className="fad fa-copy" />
				คัดลอก
			</span>
		</div>
	)
}

export default WalletDepositItem
