import moment from 'moment'
import React, { FC } from 'react'
import { Transaction } from '../../@types/transaction'
import { getBankName } from '../../helpers/bank'
import { format } from '../../helpers/number'
import StatusBadge from './StatusBadge'

interface WithdrawListProps {
	data: Transaction[]
}
const WithdrawList: FC<WithdrawListProps> = ({ data }) => {
	if (data.length <= 0) {
		return (
			<div className="notice__list">
				<p className="warning">
					<i className="fad fa-exclamation-square" />
					<span>คุณไม่มีรายการถอนเงิน</span>
				</p>
			</div>
		)
	}

	return (
		<>
			{data.map((tx) => (
				<div className="history" key={tx.id}>
					<div className="history__type">
						<h1>ถอนเงิน</h1>
						<p>
							ไปยัง <b>{getBankName(tx.transferTo)}</b>
						</p>
					</div>
					<div className="history__amount">
						<StatusBadge status={tx.status} />
						<h1>฿ {format(tx.amount)}</h1>
						<small>
							{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}
						</small>
					</div>
				</div>
			))}
		</>
	)
}

export default WithdrawList
