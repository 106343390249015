import React, { FC } from 'react'
import { Member } from '../../@types/member'
import { format } from '../../helpers/number'
import WithdrawForm from './WithdrawForm'

interface WithdrawContainerProps {
	credit: number
	info: Member
}
const WithdrawContainer: FC<WithdrawContainerProps> = ({ credit, info }) => {
	return (
		<div className="profile">
			<div className="title-bar title-bar--sub-title">
				<h1>รายละเอียดการถอน</h1>
			</div>
			<div className="profile__col profile__col--withdraw">
				<h2>จำนวนเงินที่ถอนได้</h2>
				<p className="highlight">฿ {format(credit)}</p>
			</div>
			<div className="profile__col profile__col--withdraw xs-right">
				<h2>ยอดถอนขั้นต่ำ</h2>
				<p className="danger">฿ {format(info.minWithdraw)}</p>
			</div>
			<div className="profile__col--form">
				<WithdrawForm initAmount={credit} />
			</div>
		</div>
	)
}

export default WithdrawContainer
