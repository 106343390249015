import axios from 'axios'
import qs from 'querystring'
import React, { FC, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { makePath } from '../../helpers/path'

interface BetflixResponse {
	data: {
		status: string
		msg: string
		login_token: string
	}
}

interface BetflixGameProps {
	member: Member
}
const BetflixGame: FC<BetflixGameProps> = ({ member }) => {
	const history = useHistory()
	const initBetflixGame = useCallback(async () => {
		try {
			const { data } = await axios.post<void, BetflixResponse>(
				'https://api.psg777.com/v1/userlogin',
				qs.stringify({
					username: member.game.username,
					password: member.game.password,
				})
			)

			if (data.status === 'success') {
				window.location.href = `https://www.betflik.com/login/apiLogin/${data.login_token}?returnUrl=${window.location.href}`
			} else {
				toast.error(data.msg)
			}
		} catch ({ message }) {
			toast.error(message)
			history.push(makePath('/wallet'))
		}
	}, [member, history])
	useEffect(() => {
		initBetflixGame()
	}, [initBetflixGame])

	return <div>Redirecting ...</div>
}

export default BetflixGame
